.circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: var(--light-blue);
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.center {
    align-items: center;
}

.header {
    position: sticky;
    top: 0px;
    left: 0;
    padding-top: 1px;
    z-index: 9999;
    background-color: white;
}

.historyContainer {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.historyContainerMobile {
        width: 100%;
        padding: 0;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            position: relative;
        }
    }

    .circle {
        z-index: 2;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        background-color: var(--light-blue-color);
        background-image: url('../../Assets/truck-line.svg');
        margin-right: 20px;
        border: 4px solid var(--light-grey-color);

        &.success {
            background-color: var(--light-green-color);
            background-size: 15px;
            background-image: url('../../Assets/success.svg');
        }

        &.error {
            background-color: var(--light-red-color);
            background-image: url('../../Assets/messageIcons/error.svg');
        }

    }

    .stroke {
        width: 4px;
        position: absolute;
        left: 18px;
        top: 30px;
        height: 100%;
        z-index: 1;

        &.success {
            background-color: var(--light-green-color);
        }

    }

    .headline {}

    .time {}

}

.proofOfDeliveryContainer {
    img {
        width: auto;
        height: auto;
        max-width: 100px;
        max-height: 200px;
        background-color: white;
        border-radius: 10px;
        object-fit: contain;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}

.notification {
    width: calc((100vw - 500px) * 0.8);
    padding: 20px;
    border-radius: 15px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 20px;
    left: calc((100vw - 500px) / 2);
    transform: translateX(-50%);
    z-index: 99;

    h3 {
        margin: 0
    }

    .icon {
        display: block;
        margin-right: 20px;

        .circle {
            z-index: 2;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 25px;
            background-color: var(--light-blue-color);
            background-image: url('../../Assets/truck-line.svg');
    
            &.success {
                background-color: var(--light-green-color);
                background-size: 20px;
                background-image: url('../../Assets/success.svg');
            }
    
            &.error {
                background-color: var(--light-red-color);
                background-image: url('../../Assets/messageIcons/error.svg');
            }
    
        }
    }

    .content {
        display: block;
    }
}