.mapContainer {
    height: calc(100vh);

    @media screen and (max-width: 960px) {
        height: 300px
    }
}

// .marker {
//     width: 34px;
//     height: 34px;
//     background-color: transparent;
//     border-radius: 100%;
//     display: block;
//     background-image: url('../../Assets/mapicons/marker.svg');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     cursor: pointer;

//     &.highlighted {
//         width: 50px;
//         height: 50px;
//     }

//     &.lowlight {
//         opacity: 0.4;
//     }
// }
/*
.marker {
position: absolute;
    margin-top: -34px;
    top: 40%;
    left: 50%;
    border-radius: 50%;
    background-color: #1E9BE4;
    border-color: #1E9BE4;
    width: 30px;
    height: 30px;

    &::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -26px;
        left: 2px;
        background-color: transparent;
        border: 13px solid transparent;
        border-top: 20px solid #1E9BE4;
    }
    */
.marker {
    position: absolute;
    margin-top: -24px;
    top: 40%;
    left: 50%;
    border-radius: 4px;
    background-color: #1E9BE4;
    width: 23px;
    height: 23px;

    &::after {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        bottom: -18px;
        left: 3px;
        border-radius: 1px;
        background-color: transparent;
        border: 8px solid transparent;
        border-top: 10px solid #1E9BE4;
    }

    .number {
        position: absolute;
        width: 22px;
        height: 22px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        color: white;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        font-weight: 800;
    }

    &.start {
        background-image: url('../../Assets/mapicons/start.svg');
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
    }

    &.driverMarker {
        position: absolute;
        margin-top: 0;
        margin-left: 0;
        border-radius: 100%;
        background-color: #1E9BE4;
        border-color: #1E9BE4;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        border: 4px solid white;

        &::after {
            position: absolute;
            content: '';
            width: 0px;
            height: 0px;
            bottom: -26px;
            left: 2px;
            background-color: transparent;
            border: 13px solid transparent;
            border-top: 20px solid #1E9BE4;
            display: none;
        }
    }

    &.success {
        background-image: url('../../Assets/mapicons/success.svg');
        background-position: center;
        background-size: 14px;
        background-repeat: no-repeat;
    }

    &.failed,
    &.problem {
        background-image: url('../../Assets/mapicons/error.svg');
        background-position: center;
        background-size: 13px;
        background-repeat: no-repeat;
    }

    &.dropoff {
        background-image: url('../../Assets/mapicons/dropofflocation.svg');
        background-position: center;
        background-size: 13px;
        background-repeat: no-repeat;
    }

    &.b1 {
        background-color: #0C9489;

        &::after {
            border-top-color: #0C9489;
        }
    }

    &.b2 {
        background-color: #48D3EA;

        &::after {
            border-top-color: #48D3EA;
        }
    }

    &.b3 {
        background-color: #03A4E5;

        &::after {
            border-top-color: #03A4E5;
        }
    }

    &.b4 {
        background-color: #1B75BB;

        &::after {
            border-top-color: #1B75BB;
        }
    }

    &.b5 {
        background-color: #65A324;

        &::after {
            border-top-color: #65A324;
        }
    }

    &.b6 {
        background-color: #AAB927;

        &::after {
            border-top-color: #AAB927;
        }
    }

    &.b7 {
        background-color: #9334E6;

        &::after {
            border-top-color: #9334E6;
        }
    }

    &.b8 {
        background-color: #DB1680;

        &::after {
            border-top-color: #DB1680;
        }
    }

    &.b9 {
        background-color: #EA5814;

        &::after {
            border-top-color: #EA5814;
        }
    }

    &.b10 {
        background-color: #EAD114;

        &::after {
            border-top-color: #EAD114;
        }
    }

    &.b11 {
        background-color: #E11D47;

        &::after {
            border-top-color: #E11D47;
        }
    }

    &.b12 {
        background-color: #62718C;

        &::after {
            border-top-color: #62718C;
        }
    }

    &.b13 {
        background-color: #910000;

        &::after {
            border-top-color: #910000;
        }
    }
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.clusterMarker {
    display: block;
    background-color: white;
    width: 20px;
    border-radius: 100%;
    height: 20px;
}

.clusterMarker0 {
    background-color: red;
}

.clusterMarker1 {
    background-color: green;
}

.clusterMarker2 {
    background-color: blue;
}