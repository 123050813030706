.container {
    width: 500px;
    top: 0;
    left: 0;
    padding: 20px;
    height: calc(100vh);
    position: absolute;
    background-color: var(--light-grey-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: scroll;

    @media screen and (max-width: 1200px) {
        width: 350px;
    }

    @media screen and (max-width: 960px) {
        padding-top: 20px;
        width: 100%;
        position: relative;
        height: auto;
        overflow: visible;
    }

    :global(.MuiPaper-root) {

        &:global(.Mui-expanded) {
            padding-bottom: 10px;
        }

        &::before {
            display: none;
        }

        &:last-child {
            border-bottom: 0px solid var(--light-grey-color);
        }

        :global(.MuiAccordionDetails-root) {
            padding: 0;
            display: flex;
            justify-items: center;
            flex-direction: column;
            align-items: center;
        }

        :global(.MuiButtonBase-root) {
            min-height: 30px;
        }

        :global(.MuiAccordionSummary-root) {

            @media screen and (max-width: 1200px) {
                display: none;
            }

            padding: 0;
            position: relative;

            &:global(.Mui-expanded) {
                img {
                    transform: rotate(180deg);
                }
            }

            img {
                transition: 0.2s ease all;
                position: absolute;
                right: 10px;
                top: 19px;
            }
        }

        :global(.MuiAccordionSummary-content) {
            margin: 10px 0;
        }

    }

    a {
        padding: 15px 5px;
        width: 100%;
        margin: 5px 0;
        text-decoration: none;
        color: var(--text-color);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 15px;
        transition: 0.2s ease all;

        // @media screen and (max-width: 1200px) {
        //     width: 50px;
        //     height: 50px;
        //     justify-content: center;

        //     svg {
        //         margin-right: 0;
        //     }

        //     span {
        //         display: none;
        //     }
        // }

        &:hover,
        &.current {
            color: white;
            background: linear-gradient(#2D93E0, #01ACED);

            svg {
                fill: white !important;
                stroke: white !important;
            }
        }

        &:hover {
            span {
                &::after {
                    width: 100%;
                }
            }
        }

        svg {
            height: 20px;
            margin-right: 10px;
            margin-left: 8px;
            fill: var(--text-color);
            stroke: var(--text-color);

            @media screen and (max-width: 1200px) {
                margin-right: 0px;
            }
        }
    }

}