:root {
    --black-color: #000000;
    --primary-color: #1C9DE5;
    --light-blue: #D0EFFC;
    --text-color: #82817C;
    --white-color: #ffffff;
    --white-yellow-color: #FAF3D9;
    --gray-color-600: #2A2C2B;
    --gray-color-500: #484848;
    --gray-color-400: #BCBCBC;
    --gray-color-300: #00000029;
    --gray-color-200: #0000001F;
    --mint-color: #E4F8EF;
    --green-color: #A0E4A9;
    --red-color: #E06D6D;
    --yellow-color: #F2D596;
    --light-grey-color: #F2F3F5;
    --darker-grey-color: #E3E3E3;

    --light-green-color: #C7EFCD;
    --light-blue-color: #A3D7F5;
    --light-red-color: #F5C3C3;

}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


body {
    background-color: #FAFAFA;
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

div {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
    color: var(--black-color);
}

h4 {
    font-size: 17px;

    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }
}

hr {
    margin: 30px 0;
    height: 2px;
    width: 80%;
    background: var(--darker-grey-color);
    border: 0;
    border-radius: 2px;
    display: block;
}

a {
    color: #14A1E7;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.align_right {
    text-align: right;
    justify-content: flex-end;

    @media screen and (max-width: 960px) {
        text-align: left;
        justify-content: flex-start;
    }
}

.logo {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;

    @media screen and (max-width: 960px) {
        width: 90px;
        margin-bottom: 0px;
        margin-top: 10px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}

.smallListContainer {
    width: 100%;
    max-height: 300px;
    overflow: scroll;

    &.fullHeight {
        overflow: visible !important;
        max-height: 99999999px !important;
    }

    .smallListItem {
        width: 100%;
        border-radius: 5px;
        margin: 10px 0px;
        cursor: pointer;
        padding: 10px 10px;
        display: block;
        font-size: 13px;
        position: relative;
        background-color: white;
        transition: 0.2s ease all;

        &.white {

            &:hover,
            &.selected {
                background-color: white;
                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
            }
        }

        &:hover,
        &.selected {
            background-color: var(--light-grey-color);
        }

        &.expandable {
            border-radius: 15px;
            cursor: inherit;

            .expendableIcon {
                position: absolute;
                right: 10px;
                top: calc(50% - 8px);
                width: 16px;
                height: 16px;
                opacity: 0.8;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('./Assets/navigationIcons/arrow_down.svg');
                transform: rotate(-90deg);
                transition: 0.2s ease all;
            }
        }

        .header {
            position: relative;
            display: block;
            cursor: pointer;
            padding: 10px;

            .MuiCheckbox-root {
                right: 40px;
                top: calc(50% - 21px);
                position: absolute;
            }
        }

        .innerWrapper {
            width: 100%;
            max-height: 400px;
            display: block;
            overflow: scroll;
            // padding: 0 10px;

            .smallListItem {

                &:last-child {
                    margin-bottom: 0;
                }

                &:first-child {
                    margin-top: 10px;
                }
            }
        }

        &.open {
            .expendableIcon {
                transform: rotate(0deg);
            }
        }

        .badge {
            display: inline-block;
            margin-right: 10px;
            margin-top: 4px;
            border-radius: 5px;
            background-color: var(--light-blue);
            padding: 3px 5px;
        }
    }
}

.closeButton {
    width: 40px;
    height: 40px;
    background-image: url(./Assets/dark_x.svg);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
        background-color: var(--grey-color);
    }

}

.saveBadge {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--light-blue);
    color: var(--primary-color);
    display: inline-block;
    position: sticky;
    float: right;
    z-index: 99999;
}

/* TextInput */
.MuiAutocomplete-root {
    width: 100% !important;
}

.MuiAutocomplete-inputRoot {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .MuiAutocomplete-input {
        padding-top: 30px !important;
        padding-bottom: 10px !important;
    }

}

.MuiInputLabel-root {
    top: 8px !important;
    transform: translate(14px, 14px) scale(1) !important;
    height: 25px;
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;

    &.Mui-focused,
    &.MuiFormLabel-filled {
        color: inherit !important;
        top: 20px !important;
        margin-left: 2px;
        transform: translate(14px, -9px) scale(0.75) !important;

        &.Mui-error {
            color: red !important;
        }
    }
}

.MuiTextField-root {
    width: 100%;
    margin-bottom: 20px !important;

    input {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    textarea {
        padding-top: 15px;
    }


    .MuiOutlinedInput-root {
        border: 2px solid white !important;
        border-radius: 15px !important;
        background-color: white;

        .MuiInputAdornment-root {
            transition: 0.2s ease all;
        }

        &.Mui-focused {
            .MuiInputAdornment-root {
                transform: translate(0px, 8px);
            }
        }

    }

    &.dark .MuiOutlinedInput-root {
        background-color: #F2F3F5;
    }

    &.noMargin {
        margin-bottom: 0px !important;
    }

    &.search input {
        background-image: url('./Assets/navigationIcons/search.svg');
        background-position: center left 15px;
        background-repeat: no-repeat;
        background-size: 20px;
        padding-left: 50px !important;
    }

    &.small {
        width: 300px;
        margin-bottom: 0px !important;

        input {
            height: 20px !important;
            padding: 15px;
        }
    }

    &.extrasmall {
        width: 100%;
        margin-bottom: 0px !important;

        input {
            height: 20px !important;
            padding: 15px;
        }
    }

    &.hasIcon {

        input {
            // padding-left: 5px;
        }

        .MuiInputLabel-root {
            // transform: translate(42px, 16px) scale(1);
            display: flex;
            align-items: center;
            line-height: 1rem;

            img {
                width: 18px;
                object-fit: contain;
                margin-right: 10px;
            }

            &.Mui-focused,
            &.MuiFormLabel-filled {
                // transform: translate(42px, -9px) scale(0.75);

                &.Mui-error {
                    color: red !important;
                }
            }
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0 !important
    }

}

/* Select */
.MuiFormControl-root {
    width: 100%;

    &.white {
        .MuiFilledInput-root {
            background-color: white !important;
        }
    }

    .MuiFilledInput-root {
        margin-bottom: 20px !important;
        border: 0px !important;
        background-color: var(--light-grey-color) !important;
        border-radius: 15px !important;
        height: 67px;
        padding-left: 4px !important;
        text-align: left;

        &::before,
        &::after {
            display: none;
        }
    }

    .MuiSelect-filled {
        background: none !important;
    }
}

/* Card */
.MuiCard-root {
    border: 2px solid var(--light-grey-color);
    box-shadow: none !important;
    border-radius: 15px !important;
    padding: 20px;
    overflow: visible !important;
    align-items: center;

    &.dark {
        background-color: #F2F3F5;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08) !important;
        }
    }

    &.noBorder {
        border: 0px solid var(--light-grey-color);
    }

    .MuiFilledInput-root {
        margin-bottom: 0px !important;
    }

    .MuiGrid-item {
        align-items: center;
        display: flex;
    }

    h4 {
        margin: 0;
    }
}




.cuttext {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-height: 1.2em;
    white-space: nowrap;
}

/* Page Loader */
.loadingWrapper {

    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loadingContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        img {
            width: 80%;
            max-width: 200px;
        }

        .loader-ellipsis,
        .loader-ellipsis div {
            box-sizing: border-box;
        }

        .loader-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            transform: scale(1.2);
            margin-left: 30px;
        }

        .loader-ellipsis div {
            position: absolute;
            top: 33.33333px;
            width: 13.33333px;
            height: 13.33333px;
            border-radius: 50%;
            background-color: #0F8ED2;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

        .loader-ellipsis div:nth-child(1) {
            left: 8px;
            animation: loader-ellipsis1 0.6s infinite;
            opacity: 1;
        }

        .loader-ellipsis div:nth-child(2) {
            left: 8px;
            animation: loader-ellipsis2 0.6s infinite;
            opacity: 0.75;
        }

        .loader-ellipsis div:nth-child(3) {
            left: 32px;
            animation: loader-ellipsis2 0.6s infinite;
            opacity: 0.5;
        }

        .loader-ellipsis div:nth-child(4) {
            left: 56px;
            animation: loader-ellipsis3 0.6s infinite;
            opacity: 0.25;
        }

        @keyframes loader-ellipsis1 {
            0% {
                transform: scale(0);
            }

            100% {
                transform: scale(1);
            }
        }

        @keyframes loader-ellipsis3 {
            0% {
                transform: scale(1);
            }

            100% {
                transform: scale(0);
            }
        }

        @keyframes loader-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }

            100% {
                transform: translate(24px, 0);
            }
        }

    }
}

.middleContainer {
    position: absolute;
    width: calc(40% - 150px);
    left: 300px;
    top: 70px;
    height: calc(100% - 70px);
    overflow: scroll;

    @media screen and (max-width: 1200px) {
        width: calc(40% - 35px);
        left: 70px;
    }
}

.rightContainer {
    position: absolute;
    width: calc(60% - 150px);
    left: calc(40% + 150px);
    background-color: white;
    height: calc(100vh);
    overflow: scroll;

    @media screen and (max-width: 1200px) {
        width: calc(60% - 35px);
        left: calc(40% + 35px);
    }
}

.mapContainer {
    position: absolute;
    width: calc(100% - 500px);
    left: 500px;
    top: 0;
    background-color: white;
    height: calc(100%);

    @media screen and (max-width: 1200px) {
        width: calc(100% - 350px);
        left: 350px;
    }

    @media screen and (max-width: 960px) {
        position: relative;
        width: 100%;
        left: 0;
        height: 200px !important;
    }


}

.MuiSkeleton-root {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-radius: 25px !important;
    margin-bottom: 20px;
    max-width: 100%;
}




@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

.animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}

.MuiCircularProgress-svg {
    color: var(--primary-color);
}

.MuiPopover-root {
    z-index: 999999999 !important;
}

.mapboxgl-ctrl-attrib-inner {
    a {
        padding: inherit !important;
        width: inherit !important;
        margin: inherit !important;
        text-decoration: none !important;
        color: var(--text-color);
        display: inherit !important;
        align-items: inherit !important;
        box-sizing: inherit !important;
        border-radius: inherit !important;
        transition: inherit !important;

        &:hover {
            background: none !important;
            color: inherit !important;
        }
    }
}

.sideButton {
    position: 'absolute';
    right: 10;

    @media screen and (max-width: 960px) {
        position: relative;
    }
}

.mobileSmall {
    @media screen and (max-width: 960px) {
        font-size: 80%;
    }
}

.loginCard {
    width: '90%';
    max-width: 600px;
    margin: auto;
    padding: 30px;

    @media screen and (max-width: 960px) {
        padding: 0 20px !important;
        background-color: transparent !important;
        border: 0;
        width: 100% !important;
    }
}