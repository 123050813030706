.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    padding: 20px 0;

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}